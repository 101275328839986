(() => {

    const gebruiker = document.location.host.split('.')[0]

//   let url = document.location.toString()
//   let jsonUrl = decodeURI(url.split('?').slice(-1))
//   console.log(jsonUrl)
//   const gebruikers2 = JSON.parse(jsonUrl)

//  const gebruiker = "foobar"

//   const gebruiker =

    const gebruikers = {
        "john": {
            "tekstVelden": {
                "field-firstname": "John",
                "field-lastname": "Horton",
                "field-birthday": "17/02/1935",
                "field-lieunaissance": "Pays de Galles",
                "field-address": "Le Mas Rodier",
                "field-town": "St Laurent de Céris",
                "field-zipcode": 16450
            },
            "aanvinkVakjes": {
                "checkbox-travail": "travail",
                "checkbox-courses": "courses",
                "checkbox-sante": "sante",
                "checkbox-famille": "famille",
                "checkbox-sport": "sport",
                "checkbox-judiciaire": "judiciaire",
                "checkbox-missions": "missions"
            }
        },
        "thomas": {
            "tekstVelden": {
                "field-firstname": "Thomas",
                "field-lastname": "Buxó",
                "field-birthday": "27/07/1968",
                "field-lieunaissance": "Toulouse",
                "field-address": "Le Mas Rodier",
                "field-town": "St Laurent de Céris",
                "field-zipcode": 16450
            },
            "aanvinkVakjes": {
                "checkbox-travail": "travail",
                "checkbox-courses": "courses",
                "checkbox-sante": "sante",
                "checkbox-famille": "famille",
                "checkbox-sport": "sport",
                "checkbox-judiciaire": "judiciaire",
                "checkbox-missions": "missions"
            }
        },
        "suzan": {
            "tekstVelden": {
                "field-firstname": "Suzan",
                "field-lastname": "Jorritsma",
                "field-birthday": "21/12/1968",
                "field-lieunaissance": "Arnhem, Pays-Bas",
                "field-address": "Le Mas Rodier",
                "field-town": "St Laurent de Céris",
                "field-zipcode": 16450
            },
            "aanvinkVakjes": {
                "checkbox-travail": "travail",
                "checkbox-courses": "courses",
                "checkbox-sante": "sante",
                "checkbox-famille": "famille",
                "checkbox-sport": "sport",
                "checkbox-judiciaire": "judiciaire",
                "checkbox-missions": "missions"
            }
        }
    }

    const customize = () => {
        // Tekstvelden
        Object.entries(gebruikers[gebruiker].tekstVelden).map(duo => document.getElementById(duo[0]).value = duo[1])
            // Aanvinkvakjes
        Object.entries(gebruikers[gebruiker].aanvinkVakjes).filter(duo => duo[0] === 'checkbox-courses').map(el => document.getElementById(el[0]).checked = true)
            // setTimeout(() => $('#generate-btn').click(), 1000)
    }

    document.addEventListener('load', customize())

})();
